<template>
  <div class="card-list-header">
    <textarea
      ref="title"
      v-model="column.title"
      rows="1"
      :disabled="activeTextarea === false"
      class="column-title gray-text"
      @blur="disabledTextarea"
      @keydown.enter="$refs.title.blur()"
    />

    <el-dropdown>
      <span class="el-dropdown-link">
        <el-button
          slot="reference"
          icon="el-icon-setting"
          :style="{ border: 'none', 'background-color': '#f7f7f7'}"
          size="mini"
          circle
        />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <p @click="editColumn">
            Edit list
          </p>
        </el-dropdown-item>
        <el-dropdown-item>
          <p
            style="color: #d31d36"
            @click="$emit('delete-column', column)"
          >
            Delete list
          </p>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'ColumnHeader',
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTextarea: false,
      isOpened: false,
    };
  },
  methods: {
    editColumn() {
      this.activeTextarea = true;
      this.$nextTick(() => this.$refs.title.focus());
      this.isOpened = false;
    },
    disabledTextarea() {
      this.column.update();
      this.activeTextarea = false;
    },
  },
};
</script>

<style scoped>
.column-title {
  width: 83%;
  padding: 3px 7px;
  border: none;
  color: #525252;
  font-size: 16px;
  resize: none;
  font-weight: 500;
  background-color: #f7f7f7;
  line-height: 1.2;
}
.card-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.card-list-header h3 {
  margin: 0 0 13px 0;
  color: #212529;
}
</style>
